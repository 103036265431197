<template>
  <div class="active-detail">
    <el-form ref="postForm" :rules="rules" :model="postForm" label-width="120px" size="medium">
      <el-form-item label="标题" class="title" prop="title">
        <el-input v-model="postForm.title"></el-input>
      </el-form-item>
      <el-form-item label="产品ID" class="desc" prop="product_id">
        <el-radio-group v-model="postForm.product_id">
          <el-radio v-for="product in products" :key="product.id" :label="product.id">{{
            product.name
          }}</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="分享主标题" class="title" prop="share_title">
        <el-input
          type="text"
          v-model="postForm.share_title"
          maxlength="30"
          show-word-limit
        ></el-input>
      </el-form-item>
      <el-form-item label="分享副标题" class="desc" prop="share_sub_title">
        <el-input
          type="text"
          v-model="postForm.share_sub_title"
          maxlength="50"
          show-word-limit
        ></el-input>
      </el-form-item>
      <el-form-item label="分享ID" class="desc" prop="share_id">
        <el-input type="text" v-model="postForm.share_id"></el-input>
      </el-form-item>
      <el-form-item label="分享缩略图" prop="share_thumbnail" :required="true">
        <el-upload
          action="https://www.ddpurse.com/uploadImage"
          list-type="picture-card"
          :limit="1"
          :file-list="appIconList"
          :on-success="uploadSuccess"
          :on-remove="handleEnRemove"
          accept="image/png,image/jpg,image/jpeg"
        >
          <i class="el-icon-plus"></i>
        </el-upload>
      </el-form-item>
      <el-form-item>
        <div class="copy-box" v-for="code in codes" :key="code.id">
          <div class="lable">{{ code.name }}</div>
          <CopyText :text="code.code" />
        </div>
      </el-form-item>
      <el-form-item label="" prop="content">
        <!-- <tinymce v-model="postForm.content" id="tinymce-content" :height="300" /> -->
        <div class="commom-editor">
          <el-button type="primary" size="small" @click="handleGetImageUrl">
            替换图片/背景
          </el-button>
          <div id="editor"></div>
          <!-- <ckeditor tag-name="textarea" :editor="editor" v-model="postForm.content"></ckeditor> -->
        </div>
      </el-form-item>
      <el-form-item>
        <el-button @click="onCancel">返回</el-button>
        <el-button type="primary" :loading="loading" @click="onSubmit">
          {{ isEdit ? "保存" : "创建" }}
        </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
// import Tinymce from "@/components/Tinymce/index.vue";
// import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
// import "@ckeditor/ckeditor5-build-classic/build/translations/zh-cn";
import {
  getActiveInfo,
  editActive,
  getActiveProducts,
  getActiveCode,
  uploadImageUrl,
} from "@/api/active";
import CopyText from "@/components/CopyText.vue";

function loadJs(src) {
  return new Promise((resolve, reject) => {
    let script = document.createElement("script");
    script.type = "text/javascript";
    script.src = src;
    document.body.appendChild(script);

    script.onload = () => {
      resolve();
    };
    script.onerror = () => {
      reject();
    };
  });
}

async function loadUEditor() {
  if (window.UE) {
    return true;
  }
  await loadJs("https://ent.xiumi.us/ue/ueditor.config.js");
  await loadJs("https://ent.xiumi.us/ue/ueditor.all.min.js");
}

export default {
  components: {
    // Tinymce,
    CopyText,
  },
  props: {
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.fetchProducts();
    this.fetchCodes();
  },
  created() {
    this.init();
  },
  data() {
    return {
      postForm: {},
      loading: false,
      rules: {
        title: [{ required: true, message: "请输入标题", trigger: "blur" }],
        product_id: [{ required: true, message: "请选择产品ID", trigger: "blur" }],
        share_title: [{ required: true, message: "请输入分享主标题", trigger: "blur" }],
        share_sub_title: [{ required: true, message: "请输入分享副标题", trigger: "blur" }],
        // content: [{ required: true, message: "请输入富文本", trigger: "blur" }],
      },
      // editor: ClassicEditor,
      products: [],
      codes: [],
      myEditor: null,
    };
  },
  onReady(editor) {
    // 自定义上传图片插件
    // editor.plugins.get( 'FileRepository' ).createUploadAdapter = ( loader ) => {
    //      return new MyUploadAdapter( loader );
    //  };
  },
  computed: {
    appIconList() {
      if (this.postForm.share_thumbnail) {
        return [
          {
            name: "share_thumbnail",
            url: this.postForm.share_thumbnail,
          },
        ];
      }
      return [];
    },
  },
  methods: {
    async init() {
      // if (window.CKEDITOR) {
      //   this.initSDK();
      //   return;
      // }
      // await loadJs("https://cdn.ckeditor.com/ckeditor5/36.0.1/super-build/ckeditor.js");
      // this.initSDK();
      if (this.isEdit) {
        const id = this.$route.params && this.$route.params.id;
        await this.fetchData(id);
      }
      await loadUEditor();
      this.initUEditor();
    },

    async initUEditor() {
      this.myEditor = window.UE.getEditor("editor");
      console.log(this.myEditor);
      this.myEditor.ready(() => {
        // 回填content
        if (this.isEdit) {
          const html = this.myEditor.getContent();
          if (!html && this.postForm.content) {
            this.myEditor.setContent(this.postForm.content);
          }
        }
      });
    },
    // async initSDK() {
    //   this.myEditor = await CKEDITOR.ClassicEditor.create(document.getElementById("editor"), {
    //     // https://ckeditor.com/docs/ckeditor5/latest/features/toolbar/toolbar.html#extended-toolbar-configuration-format
    //     toolbar: {
    //       items: [
    //         "exportPDF",
    //         "exportWord",
    //         "|",
    //         "findAndReplace",
    //         "selectAll",
    //         "|",
    //         "heading",
    //         "|",
    //         "bold",
    //         "italic",
    //         "strikethrough",
    //         "underline",
    //         "code",
    //         "subscript",
    //         "superscript",
    //         "removeFormat",
    //         "|",
    //         "bulletedList",
    //         "numberedList",
    //         "todoList",
    //         "|",
    //         "outdent",
    //         "indent",
    //         "|",
    //         "undo",
    //         "redo",
    //         "-",
    //         "fontSize",
    //         "fontFamily",
    //         "fontColor",
    //         "fontBackgroundColor",
    //         "highlight",
    //         "|",
    //         "alignment",
    //         "|",
    //         "link",
    //         "insertImage",
    //         "blockQuote",
    //         "insertTable",
    //         "mediaEmbed",
    //         "codeBlock",
    //         "htmlEmbed",
    //         "|",
    //         "specialCharacters",
    //         "horizontalLine",
    //         "pageBreak",
    //         "|",
    //         "textPartLanguage",
    //         "|",
    //         "sourceEditing",
    //       ],
    //       shouldNotGroupWhenFull: true,
    //     },
    //     // Changing the language of the interface requires loading the language file using the <script> tag.
    //     // language: 'es',
    //     list: {
    //       properties: {
    //         styles: true,
    //         startIndex: true,
    //         reversed: true,
    //       },
    //     },
    //     // https://ckeditor.com/docs/ckeditor5/latest/features/headings.html#configuration
    //     heading: {
    //       options: [
    //         { model: "paragraph", title: "Paragraph", class: "ck-heading_paragraph" },
    //         { model: "heading1", view: "h1", title: "Heading 1", class: "ck-heading_heading1" },
    //         { model: "heading2", view: "h2", title: "Heading 2", class: "ck-heading_heading2" },
    //         { model: "heading3", view: "h3", title: "Heading 3", class: "ck-heading_heading3" },
    //         { model: "heading4", view: "h4", title: "Heading 4", class: "ck-heading_heading4" },
    //         { model: "heading5", view: "h5", title: "Heading 5", class: "ck-heading_heading5" },
    //         { model: "heading6", view: "h6", title: "Heading 6", class: "ck-heading_heading6" },
    //       ],
    //     },
    //     // https://ckeditor.com/docs/ckeditor5/latest/features/editor-placeholder.html#using-the-editor-configuration
    //     placeholder: "Welcome to CKEditor 5!",
    //     // https://ckeditor.com/docs/ckeditor5/latest/features/font.html#configuring-the-font-family-feature
    //     fontFamily: {
    //       options: [
    //         "default",
    //         "Arial, Helvetica, sans-serif",
    //         "Courier New, Courier, monospace",
    //         "Georgia, serif",
    //         "Lucida Sans Unicode, Lucida Grande, sans-serif",
    //         "Tahoma, Geneva, sans-serif",
    //         "Times New Roman, Times, serif",
    //         "Trebuchet MS, Helvetica, sans-serif",
    //         "Verdana, Geneva, sans-serif",
    //       ],
    //       supportAllValues: true,
    //     },
    //     // https://ckeditor.com/docs/ckeditor5/latest/features/font.html#configuring-the-font-size-feature
    //     fontSize: {
    //       options: [10, 12, 14, "default", 18, 20, 22],
    //       supportAllValues: true,
    //     },
    //     // Be careful with the setting below. It instructs CKEditor to accept ALL HTML markup.
    //     // https://ckeditor.com/docs/ckeditor5/latest/features/general-html-support.html#enabling-all-html-features
    //     htmlSupport: {
    //       allow: [
    //         {
    //           name: /.*/,
    //           attributes: true,
    //           classes: true,
    //           styles: true,
    //         },
    //       ],
    //     },
    //     // Be careful with enabling previews
    //     // https://ckeditor.com/docs/ckeditor5/latest/features/html-embed.html#content-previews
    //     htmlEmbed: {
    //       showPreviews: true,
    //     },
    //     // https://ckeditor.com/docs/ckeditor5/latest/features/link.html#custom-link-attributes-decorators
    //     link: {
    //       decorators: {
    //         addTargetToExternalLinks: true,
    //         defaultProtocol: "https://",
    //         toggleDownloadable: {
    //           mode: "manual",
    //           label: "Downloadable",
    //           attributes: {
    //             download: "file",
    //           },
    //         },
    //       },
    //     },
    //     // https://ckeditor.com/docs/ckeditor5/latest/features/mentions.html#configuration
    //     mention: {
    //       feeds: [
    //         {
    //           marker: "@",
    //           feed: [
    //             "@apple",
    //             "@bears",
    //             "@brownie",
    //             "@cake",
    //             "@cake",
    //             "@candy",
    //             "@canes",
    //             "@chocolate",
    //             "@cookie",
    //             "@cotton",
    //             "@cream",
    //             "@cupcake",
    //             "@danish",
    //             "@donut",
    //             "@dragée",
    //             "@fruitcake",
    //             "@gingerbread",
    //             "@gummi",
    //             "@ice",
    //             "@jelly-o",
    //             "@liquorice",
    //             "@macaroon",
    //             "@marzipan",
    //             "@oat",
    //             "@pie",
    //             "@plum",
    //             "@pudding",
    //             "@sesame",
    //             "@snaps",
    //             "@soufflé",
    //             "@sugar",
    //             "@sweet",
    //             "@topping",
    //             "@wafer",
    //           ],
    //           minimumCharacters: 1,
    //         },
    //       ],
    //     },
    //     // The "super-build" contains more premium features that require additional configuration, disable them below.
    //     // Do not turn them on unless you read the documentation and know how to configure them and setup the editor.
    //     removePlugins: [
    //       // These two are commercial, but you can try them out without registering to a trial.
    //       // 'ExportPdf',
    //       // 'ExportWord',
    //       "CKBox",
    //       "CKFinder",
    //       "EasyImage",
    //       // This sample uses the Base64UploadAdapter to handle image uploads as it requires no configuration.
    //       // https://ckeditor.com/docs/ckeditor5/latest/features/images/image-upload/base64-upload-adapter.html
    //       // Storing images as Base64 is usually a very bad idea.
    //       // Replace it on production website with other solutions:
    //       // https://ckeditor.com/docs/ckeditor5/latest/features/images/image-upload/image-upload.html
    //       // 'Base64UploadAdapter',
    //       "RealTimeCollaborativeComments",
    //       "RealTimeCollaborativeTrackChanges",
    //       "RealTimeCollaborativeRevisionHistory",
    //       "PresenceList",
    //       "Comments",
    //       "TrackChanges",
    //       "TrackChangesData",
    //       "RevisionHistory",
    //       "Pagination",
    //       "WProofreader",
    //       // Careful, with the Mathtype plugin CKEditor will not load when loading this sample
    //       // from a local file system (file://) - load this site via HTTP server if you enable MathType
    //       "MathType",
    //     ],
    //   });

    //   // 回填content
    //   if (this.isEdit) {
    //     const str = this.myEditor.data.get();
    //     if (!str && this.postForm.content) {
    //       this.myEditor.data.set(this.postForm.content);
    //     }
    //   }
    // },
    async handleGetImageUrl() {
      const imgUrlRegex2 = /(http[s]?:\/\/.*?\.(?:png|jpg|gif|svg|jpeg))(\?.+)?/gi;

      if (!this.myEditor) {
        return;
      }
      const str = this.myEditor.getContent();
      if (str) {
        // const arr = str.match(imgUrlRegex);
        const arr2 = str.match(imgUrlRegex2);

        if (!arr2 || !arr2.length) {
          return;
        }
        const res = await this.uploadImageUrls([...arr2]);
        let newStr = str;
        if (res) {
          arr2.forEach((item) => {
            const newImage = res[item];
            if (newImage) {
              newStr = newStr.replace(item, newImage);
            }
          });
        }
        console.log(newStr);

        this.myEditor.setContent(newStr);
      }
    },
    async uploadImageUrls(urls) {
      try {
        const { data } = await uploadImageUrl({
          urls,
        });
        return data.data;
      } catch (error) {
        console.log(error);
        return null;
      }
    },
    async fetchData(id) {
      const params = {
        activity_id: id,
      };
      try {
        const { data } = await getActiveInfo(params);
        this.postForm = data.data;
      } catch (error) {
        console.log(error);
      }
    },
    async fetchProducts() {
      try {
        const { data } = await getActiveProducts();
        this.products = data.data.items;
        console.log("fetchProducts", data.data);
      } catch (error) {
        console.log(error);
      }
    },
    async fetchCodes() {
      try {
        const { data } = await getActiveCode();
        this.codes = data.data.items;
      } catch (error) {
        console.log(error);
      }
    },
    handleSuccess() {
      this.$message({
        title: "成功",
        message: this.isEdit ? "编辑活动页成功" : "发布活动页成功",
        type: "success",
        duration: 2000,
      });
      this.$router.replace("/app/active");
    },
    async submit(content) {
      const loadingInstance = this.$loading();
      let query = {
        ...this.postForm,
        content,
      };
      // editActive

      if (this.isEdit) {
        const { activity_id: id, ...res } = query;
      }
      console.log(query);
      try {
        const { data } = await editActive(query);
        loadingInstance.close();
        this.handleSuccess();
      } catch (error) {
        loadingInstance.close();
        console.log(error);
      }
    },
    uploadSuccess(data) {
      if (+data.code) {
        this.$message({
          message: "上传失败",
          duration: 3000,
          type: "waring",
        });
      } else {
        this.postForm.share_thumbnail = data.data;
      }
    },
    handleEnRemove(file, fileList) {
      this.postForm.share_thumbnail = "";
    },
    onSubmit() {
      const content = this.myEditor.getContent();
      if (!content) {
        this.$message.error("请输入富文本");
      }
      this.$refs["postForm"].validate((valid) => {
        if (valid) {
          this.submit(content);
        } else {
          return false;
        }
      });
    },
    onCancel() {
      this.$router.replace("/app/active");
    },
  },
};
</script>

<style lang="less" scoped>
.active-detail {
  margin: 0;
  padding: 40px 20px;

  .title {
    width: 500px;
  }

  .desc {
    width: 1000px;
  }
  .copy-box {
    display: flex;
    align-items: center;
    .lable {
      width: 150px;
      margin-right: 12px;
    }
  }

  .pic {
    width: 500px;
  }
}
.commom-editor {
  width: 375px;
  min-height: 400px;
}
</style>
